<template>
  <div>
    <div v-if="!confirmationShow" class="information-form">
      <el-form
        ref="information"
        size="small"
        :rules="rules"
        label-position="top"
        :model="information"
        label-width="80px"
      >
        <div class="item-title">项目信息</div>
        <el-form-item
          label="所属项目"
          class="label-position-left"
          prop="projectId"
        >
          {{ getProjectName(information.projectId) }}
        </el-form-item>
        <el-form-item
          label="证书类型"
          class="label-position-left"
          prop="certTemplateId"
        >
          {{ CertType(info.certType) }}
        </el-form-item>
        <div class="item-title">机构信息</div>
        <div class="item-box">
          <el-form-item label="机构名称/O" prop="orgInfoEntity.organization">
            <el-input
              v-model="information.orgInfoEntity.organization"
              disabled
            ></el-input>
          </el-form-item>
          <el-form-item
            label="统一社会信用代码"
            prop="orgInfoEntity.orgIdentNo"
          >
            <el-input
              v-model="information.orgInfoEntity.orgIdentNo"
              disabled
            ></el-input>
          </el-form-item>
          <el-form-item label="部门/OU" prop="orgInfoEntity.organizationalUnit">
            <el-input
              v-model="information.orgInfoEntity.organizationalUnit"
              disabled
            ></el-input>
          </el-form-item>
          <el-form-item
            label="企业注册省市"
            prop="orgInfoEntity.stateOrProvince"
          >
            <el-input
              :value="
                information.orgInfoEntity.stateOrProvince +
                  '/' +
                  information.orgInfoEntity.locality
              "
              disabled
            ></el-input>
          </el-form-item>
        </div>
        <div class="item-title">法定代表人信息</div>
        <div class="item-box">
          <el-form-item
            label="法定代表人姓名"
            prop="orgInfoEntity.legalPersonName"
          >
            <el-input
              v-model="information.orgInfoEntity.legalPersonName"
              disabled
            ></el-input>
          </el-form-item>
          <el-form-item
            label="法人身份证号码"
            prop="orgInfoEntity.legalPersonIdentNo"
          >
            <el-input
              v-model="information.orgInfoEntity.legalPersonIdentNo"
              disabled
            ></el-input>
          </el-form-item>
        </div>
        <template v-if="info.certType !== 2">
          <div class="item-title">用户信息</div>
          <div class="item-box">
            <el-form-item label="姓名/CN" prop="personInfoEntity.name">
              <el-input
                v-model="information.personInfoEntity.name"
                disabled
              ></el-input>
            </el-form-item>
            <el-form-item label="证件号码" prop="personInfoEntity.identNo">
              <el-input
                v-model="information.personInfoEntity.identNo"
                disabled
              ></el-input>
            </el-form-item>
            <el-form-item label="手机号" prop="personInfoEntity.mobile">
              <el-input
                v-model="information.personInfoEntity.mobile"
                disabled
              ></el-input>
            </el-form-item>
            <el-form-item label="邮箱" prop="personInfoEntity.email">
              <el-input
                v-model="information.personInfoEntity.email"
                disabled
              ></el-input>
            </el-form-item>
          </div>
        </template>
        <div class="item-title">证书信息</div>
        <el-form-item
          class="label-position-left"
          :rules="[{ required: true }]"
          label="证书有效期"
        >
          {{
            `${info.certStartTime.split(' ')[0]}至${
              info.certEndTime.split(' ')[0]
            }`
          }}
        </el-form-item>
        <div class="item-box">
          <el-form-item label="证书名称/CN" :rules="[{ required: true }]">
            <el-input
              disabled
              :value="
                info.certType !== 2
                  ? information.personInfoEntity.name
                  : information.orgInfoEntity.organization
              "
            ></el-input>
          </el-form-item>
        </div>
        <div class="item-title">
          经办人基本信息<span class="hint"
            >（经办人手机号用于接收证书密码，业务验证码，证书解锁信息等）</span
          >
        </div>
        <div class="item-box">
          <el-form-item label="经办人姓名">
            <el-input
              disabled
              v-model="agent.name"
              placeholder="请输入个人姓名"
            ></el-input>
          </el-form-item>
          <el-form-item label="经办人身份证号">
            <el-input
              disabled
              v-model="agent.identNo"
              placeholder="请输入证件号码"
            ></el-input>
          </el-form-item>
          <el-form-item label="经办人手机号">
            <el-input
              disabled
              v-model="agent.mobile"
              placeholder="请输入个人手机号"
            ></el-input>
          </el-form-item>
        </div>
      </el-form>

      <div class="item-title textLeft" v-if="info.sealInfoEntitys">
        印章图样
      </div>
      <div class="commonPatternBig" v-if="info.sealInfoEntitys">
        <img :src="info.sealInfoEntitys[0].patternFilePath" />
      </div>

      <el-form
        ref="submitForm"
        size="small"
        :rules="rules"
        label-position="top"
        :model="submitForm"
        label-width="80px"
      >
        <div class="item-title">领取方式</div>
        <el-form-item
          class="label-position-left"
          :rules="[{ required: true }]"
          label="领取方式"
        >
          <el-radio-group v-model="submitForm.shipping">
            <el-radio :label="false">自取</el-radio>
            <el-radio :label="true">邮寄到付</el-radio>
          </el-radio-group>
        </el-form-item>
        <div class="item-box" v-if="submitForm.shipping">
          <el-form-item label="收件人姓名" prop="recipientName">
            <el-input
              v-model="submitForm.recipientName"
              placeholder="请输入收件人姓名"
              minlength="2"
              maxlength="60"
            ></el-input>
          </el-form-item>
          <el-form-item label="收件人手机号" prop="recipientMobile">
            <el-input
              v-model="submitForm.recipientMobile"
              placeholder="请输入收件人手机号"
              maxlength="11"
            ></el-input>
          </el-form-item>
        </div>
        <el-form-item
          v-if="submitForm.shipping"
          label="收件地址"
          prop="mailingAddress"
        >
          <el-input
            type="textarea"
            :rows="2"
            placeholder="请输入收件地址"
            v-model="submitForm.mailingAddress"
          >
          </el-input>
        </el-form-item>
      </el-form>
      <el-button class="submitButn" @click="nextStep" type="primary"
        >下一步</el-button
      >
    </div>
    <div v-else class="confirmation">
      <div class="item-title">项目信息</div>
      <div class="item-box">
        <div>
          所属项目：<span>{{ getProjectName(information.projectId) }}</span>
        </div>
        <div>
          证书类型：<span>{{ CertType(info.certType) }}</span>
        </div>
      </div>
      <div class="item-title">机构信息</div>
      <div class="item-box">
        <div>
          机构名称/O：<span>{{ information.orgInfoEntity.organization }}</span>
        </div>
        <div>
          社会统一信用代码：<span>{{
            information.orgInfoEntity.orgIdentNo
          }}</span>
        </div>
        <div>
          部门/OU：<span>{{
            information.orgInfoEntity.organizationalUnit
          }}</span>
        </div>
        <div>
          企业注册地址：<span>{{
            information.orgInfoEntity.stateOrProvince +
              '/' +
              information.orgInfoEntity.locality
          }}</span>
        </div>
      </div>
      <div class="item-title">法定代表人信息</div>
      <div class="item-box">
        <div>
          法定代表人姓名：<span>{{
            information.orgInfoEntity.legalPersonName
          }}</span>
        </div>
        <div>
          法定代表人证件号码：<span>{{
            information.orgInfoEntity.legalPersonIdentNo
          }}</span>
        </div>
      </div>
      <template v-if="info.certType !== 2">
        <div class="item-title">用户信息</div>
        <div class="item-box">
          <div>
            姓名/CN：<span>{{ information.personInfoEntity.name }}</span>
          </div>
          <div>
            证件号码：<span>{{ information.personInfoEntity.identNo }}</span>
          </div>
          <div>
            手机号：<span>{{ information.personInfoEntity.mobile }}</span>
          </div>
          <div>
            邮箱：<span>{{ information.personInfoEntity.email || '--' }}</span>
          </div>
        </div>
      </template>
      <div class="item-title" v-if="info.sealInfoEntitys">印章图样</div>
      <div class="patternImg" v-if="info.sealInfoEntitys">
        <img :src="info.sealInfoEntitys[0].patternFilePath" />
      </div>

      <div class="item-title">证书信息</div>
      <div class="item-box">
        <div>
          证书有效期：<span>
            {{
              `${info.certStartTime.split(' ')[0]}至${
                info.certEndTime.split(' ')[0]
              }`
            }}</span
          >
        </div>
        <div>
          证书名称/CN：<span>{{
            info.certType !== 2
              ? information.personInfoEntity.name
              : information.orgInfoEntity.organization
          }}</span>
        </div>
      </div>
      <div class="item-title">经办人信息</div>
      <div class="item-box">
        <div>
          经办人姓名：<span>{{ agent.name }}</span>
        </div>
        <div>
          经办人身份证号：<span>{{ agent.identNo }}</span>
        </div>
        <div>
          经办手机号：<span>{{ agent.mobile }}</span>
        </div>
      </div>
      <div class="item-title">领取方式</div>
      <div class="item-box">
        <div>
          领取方式：<span>{{ submitForm.shipping ? '邮寄到付' : '自取' }}</span>
        </div>
        <div v-if="submitForm.shipping">
          收件人姓名：<span>{{ submitForm.recipientName }}</span>
        </div>
        <div v-if="submitForm.shipping">
          收件人手机号：<span>{{ submitForm.recipientMobile }}</span>
        </div>
        <div v-if="submitForm.shipping">
          收件地址：<span>{{ submitForm.mailingAddress }}</span>
        </div>
      </div>
      <div class="buttons">
        <el-button @click="operationSubmit" type="primary"
          >确认业务单</el-button
        >
        <el-button plain @click="confirmationShow = false">返回修改</el-button>
      </div>
    </div>
  </div>
</template>

<script>
import { validName, validMobile } from '@/utils/validate'
import { CertType } from '@/utils/typeMatch'
export default {
  props: ['projectId', 'info'],
  data() {
    return {
      CertType,
      confirmationShow: false,
      transId: '',
      projectList: [],
      information: {},
      submitForm: {
        shipping: true,
        recipientName: '',
        recipientMobile: '',
        mailingAddress: ''
      },
      rules: {
        certTemplateId: [{ required: true }],
        certDuration: [{ required: true }],
        mailingAddress: [
          { required: true, message: '请输入您的收件地址', trigger: 'blur' },
          {
            min: 2,
            max: 40,
            message: '请输入您的收件地址（2～40个字）',
            trigger: 'change'
          }
        ],
        recipientName: [
          { required: true, message: '请输入收件人姓名', trigger: 'blur' },
          {
            validator: validName,
            message: '请输入收件人真实姓名',
            trigger: 'blur'
          }
        ],
        recipientMobile: [
          { required: true, message: '请输入收件人手机号', trigger: 'blur' },
          {
            message: '请输入正确的收件人手机号',
            validator: validMobile,
            trigger: 'blur'
          }
        ],
        projectId: [{ required: true }],
        personInfoEntity: {
          name: [{ required: true }],
          identNo: [{ required: true }],
          mobile: [{ required: true }]
        },
        orgInfoEntity: {
          organization: [{ required: true }],
          organizationalUnit: [{ required: true }],
          orgIdentNo: [{ required: true }],
          stateOrProvince: [{ required: true }],
          locality: '',
          legalPersonName: [{ required: true }],
          legalPersonIdentNo: [{ required: true }],
          agentName: [{ required: true }],
          agentIdentNo: [{ required: true }],
          agentMobile: [{ required: true }],
          agentEmail: ''
        }
      },
      provinceAndCity: [],
      agent: {}
    }
  },
  created() {
    this.getProjectList()
    let transId = this.$route.query.transId
    if (transId) {
      this.transId = transId
    }
    this.information = this.info
    // children label value
    this.$api.getAccountInfo().then((res) => {
      let { name, identNo, mobile } = res.data
      this.agent = { name, identNo, mobile }
    })
    // children label value
  },
  watch: {
    'submitForm.shipping': function(val) {
      if (val) {
        this.$set(this.submitForm, 'recipientName', '')
        this.$set(this.submitForm, 'recipientMobile', '')
        this.$set(this.submitForm, 'mailingAddress', '')
      }
    }
  },
  methods: {
    nextStep() {
      this.$refs.submitForm.validate((valid) => {
        if (valid) {
          this.confirmationShow = true
        }
      })
    },
    operationSubmit() {
      let data = {
        ...this.submitForm,
        businessType: 9,
        certSn: this.info.certSn
      }
      let transId = this.$route.query.transId
      if (transId) {
        data['transId'] = transId
      }
      this.$api.operationSubmit(data).then((res) => {
        if (res.code === '0') {
          this.$parent.transId = res.data.id
          this.$router.push({
            query: { transId: res.data.id }
          })
        } else if (res.code === '306') {
          this.$alert(res.msg, '提示', {
            confirmButtonText: '继续办理',
            type: 'warning'
          }).then(() => {
            this.$router.push({ name: 'serviceCenter', query: { type: 1 } })
          })
        } else {
          this.$alert(res.msg, '提示', {
            confirmButtonText: '确定',
            type: 'warning',
            callback: () => {}
          })
        }
      })
    },
    getProjectList() {
      this.$api.getProjectList().then((res) => {
        this.projectList = res.data
      })
    },
    getProjectName(id) {
      let project = this.projectList.find((item) => {
        return item.id === id
      })
      return project ? project.name : ''
    }
  }
}
</script>

<style lang="scss" scoped>
.information-form {
  text-align: center;
  padding: 16px 24px;
  ::v-deep.el-form {
    text-align: left;
    .el-radio {
      margin: 10px 20px 10px 0px;
    }
    .customerName {
      font-size: 14px;
      color: #6c737b;
    }

    .item-box {
      display: flex;
      flex-wrap: wrap;
      .el-form-item:nth-child(3n) {
        margin-right: 0;
      }
      .el-form-item {
        width: 368px;
        margin-right: 73px;
      }
    }
    .el-form-item {
      margin-bottom: 32px;
      width: 809px;
    }
    .el-form-item__label {
      line-height: 19px;
      margin-bottom: 14px;
      font-size: 14px;
      padding: 0;
      color: #303b50;
    }
    .label-position-left {
      width: auto;
      .el-form-item__label {
        float: left;
        width: 94px;
        line-height: 32px;
        margin-bottom: 0;
      }
      .el-form-item__content {
        margin-left: 94px;
      }
    }
  }
  .submitButn {
    width: 182px;
    margin: 24px auto;
  }
}
.confirmation {
  padding: 16px 24px;
  .item-box {
    display: flex;
    flex-wrap: wrap;
    & > div {
      width: 50%;
      font-size: 14px;
      font-family: MicrosoftYaHei;
      color: #6c737b;
      line-height: 19px;
      margin-bottom: 32px;
      display: flex;
      white-space: nowrap;
      span {
        font-size: 14px;
        font-weight: bold;
        color: #303b50;
        white-space: normal;
      }
    }
  }
  .buttons {
    text-align: center;
    margin: 24px auto;
    .el-button {
      width: 182px;
    }
  }
}
.textLeft {
  text-align-last: left;
}
.patternImg {
  margin-bottom: 32px;
  img {
    width: 128px;
  }
}
</style>
